<template>
  <v-subheader v-if="visible">{{ $t('labels.transactions') }}</v-subheader>
</template>

<script>
import transactionPerimeter from '../perimeters'
export default {
  name: 'TransactionHeader',
  perimeters: [transactionPerimeter],
  computed: {
    visible() {
      return this.$transaction.isAllowed('atLeastManager')
    }
  }
}
</script>
